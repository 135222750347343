import { JobDetails } from 'src/types/Job';
import {
    computeNextStepAfterActualHours,
    isEstimatedCostConfirmationRequired,
    isTooManyVisits,
    issueAreaCoveredInPolicy,
    labourHoursToCost,
    totalCostOfJob,
} from 'src/utils/jobs';
import {
    DetermineFollowOnIsAuthorisedInput,
    determineNextFollowOnAuthorisationStep,
} from 'src/utils/determineFollowOnIsAuthorised';
import { maxLabourAuthorised } from 'src/utils/maxLabourAuthorised';
import { Account } from 'src/features/account/engineer/profile/api/getAccount.types';
import { gasSafetyQuestions } from './gasSafetyQuestions';
import {
    FormState,
    EstimatedCostOfPartsValue,
    ActualCostOfPartsValue,
    BoilerDetailsValue,
} from './types/Form';
import { QuestionType, Questions } from './types/Question';

const isStructuredPartsFlow = (jobType: string, featureFlag: boolean | undefined) =>
    ['Gas Job', 'Plumbing'].includes(jobType) &&
    process.env.REACT_APP_FLAG_AUTHORISATION_FLOW_ENABLED === 'true' &&
    featureFlag;

export const determineViewAfterPhotos = (
    partsDescription: string | null,
    jobType: string,
    featureFlag: boolean | undefined
): string => {
    if (partsDescription === null) {
        return 'haveYouFittedPartsToday';
    }

    if (isStructuredPartsFlow(jobType, featureFlag)) {
        return 'newActualCostOfParts';
    }

    return 'actualCostOfParts';
};

export const questions =
    (jobDetails: JobDetails, accountData: Account | undefined) =>
    (state: Partial<FormState>): Questions => {
        return {
            issueResolved: {
                question: 'Is the issue resolved?',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Yes',
                        nextQuestion:
                            jobDetails.sk_visit_type === 'FollowOn'
                                ? 'issueDescription'
                                : 'issueArea',
                    },
                    { value: 'No', nextQuestion: 'describeIssueAndNextSteps' },
                ],
                onSubmit: (value) => ({ isResolved: value === 'Yes' } as Partial<FormState>),
            },
            issueDescription: {
                question: 'Describe issue and steps you took to resolve it',
                type: QuestionType.TEXT,
                config: {
                    text: { fieldLength: 10000 },
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestion:
                            jobDetails.job_type === 'Gas Job'
                                ? 'gasSafetyChecks'
                                : 'partsAvailablePhotosOfFault',
                    },
                ],
                defaultValue: state.issueDescription,
                onSubmit: (value) => ({ issueDescription: value } as Partial<FormState>),
            },
            describeIssueAndNextSteps: {
                question: 'Describe issue and steps to fix',
                type: QuestionType.TEXT,
                config: {
                    text: { fieldLength: 10000 },
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestion:
                            jobDetails.job_type === 'Gas Job' ? 'boilerDetails' : 'issueArea',
                    },
                ],
                defaultValue: state.issueDescription,
                onSubmit: (value) => ({ issueDescription: value } as Partial<FormState>),
            },
            boilerDetails: {
                question: 'Please select the boiler make',
                type: QuestionType.BOILER_DETAILS,
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (_, formState) =>
                            typeof formState.isResolved !== 'undefined' && formState.isResolved
                                ? 'boilerType'
                                : 'gasSafetyChecks',
                    },
                ],
                onSubmit: (value: BoilerDetailsValue) => ({ ...value } as Partial<FormState>),
                defaultValue: {
                    boilerMake: state.boilerMake || jobDetails?.sk_boiler_make || '',
                    boilerModel: state.boilerModel || jobDetails?.sk_boiler_model || '',
                    boilerGcNumber: state.boilerGcNumber || jobDetails?.sk_boiler_gc_number || '',
                },
            },
            gasSafetyChecks: {
                question: 'Performed gas safety checks?',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Yes',
                        nextQuestionSelector: (_, formState) =>
                            typeof formState.isResolved !== 'undefined' && formState.isResolved
                                ? 'boilerDetails'
                                : 'boilerType',
                    },
                    {
                        value: 'No',
                        nextQuestionSelector: (_, formState) =>
                            typeof formState.isResolved !== 'undefined' && formState.isResolved
                                ? 'partsAvailablePhotosOfFault'
                                : 'issueArea',
                    },
                ],
                onSubmit: () => ({}),
            },
            estimatedCostOfParts: {
                question: 'Request authorisation for fix',
                type: QuestionType.ESTIMATED_COST_OF_PARTS,
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (value: EstimatedCostOfPartsValue) => {
                            if (
                                isEstimatedCostConfirmationRequired(
                                    value.estimatedHoursNeeded,
                                    value.estimatedPartsCost
                                ) ||
                                isTooManyVisits(Number(jobDetails.previous_engineer_visits))
                            ) {
                                return 'costAuthorisation';
                            }
                            return 'partsSource';
                        },
                    },
                ],
                onSubmit: (value: EstimatedCostOfPartsValue) =>
                    ({
                        ...value,
                    } as Partial<FormState>),
                defaultValue: {
                    partsDescription: state.partsDescription,
                    estimatedPartsCost: state.estimatedPartsCost,
                    estimatedHoursNeeded: state.estimatedHoursNeeded,
                },
            },
            confirmEstimatedCostOfParts: {
                question: 'Confirm authorised costs',
                type: QuestionType.CONFIRM_ESTIMATED_COST_OF_PARTS,
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'partsSource',
                    },
                ],
                onSubmit: (value: EstimatedCostOfPartsValue) =>
                    ({
                        ...value,
                    } as Partial<FormState>),
            },
            costAuthorisation: {
                question: 'Call Hometree now to confirm authorisation',
                type: QuestionType.COST_AUTHORISATION,
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: () => {
                            if (jobDetails.sk_authorise_follow_on === 'Yes') {
                                if (
                                    isStructuredPartsFlow(
                                        jobDetails.job_type,
                                        state.useStructuredParts
                                    )
                                ) {
                                    return 'confirmPartsNeeded';
                                }

                                return 'confirmEstimatedCostOfParts';
                            }
                            return 'partsAvailablePhotosOfFault';
                        },
                    },
                ],
                onSubmit: () => ({}),
            },
            haveYouFittedPartsToday: {
                question: 'Have you fitted parts today?',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Yes',
                        nextQuestion: isStructuredPartsFlow(
                            jobDetails.job_type,
                            state.useStructuredParts
                        )
                            ? 'newActualCostOfParts'
                            : 'actualCostOfParts',
                    },
                    {
                        value: 'No',
                        nextQuestion: 'actualHours',
                    },
                ],
                onSubmit: () => ({}),
            },
            actualCostOfParts: {
                question: 'Parts you purchased and fitted today',
                type: QuestionType.ACTUAL_COST_OF_PARTS,
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'actualHours',
                    },
                ],
                defaultValue: {
                    actualPartsDescription:
                        state.actualPartsDescription || jobDetails.sk_e_parts_description || '',
                    actualNetPartsCost: state.actualNetPartsCost,
                    actualGrossPartsCost: state.actualGrossPartsCost,
                },
                onSubmit: (value: ActualCostOfPartsValue) => {
                    return {
                        actualNetPartsCost: value.actualNetPartsCost,
                        actualPartsDescription: value.actualPartsDescription,
                        actualGrossPartsCost: value.actualGrossPartsCost,
                    } as Partial<FormState>;
                },
            },
            partsSource: {
                question: 'Next step:',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'canFitPartsNow',
                        copy: 'I can get and fit parts now',
                        nextQuestion: 'authorised',
                    },
                    {
                        value: 'canFitPartsAtNextVisit',
                        copy: 'I can source parts for next visit',
                        nextQuestion: 'followOnDetails',
                    },
                ],
                config: {
                    otherConfig: {
                        className: 'NextStep',
                    },
                },
                onSubmit: (value) => {
                    return {
                        partsSource: value,
                        canEngineerSourceParts: value === 'canFitPartsAtNextVisit',
                    } as Partial<FormState>;
                },
            },
            followOnDetails: {
                question: 'Time confirmed with customer for next visit',
                type: QuestionType.FOLLOW_ON_DETAILS,
                options: [{ value: 'Next', nextQuestion: 'partsAvailablePhotosOfFault' }],
                onSubmit: (value) => ({ followOnDetails: value } as Partial<FormState>),
            },
            authorised: {
                question: 'Authorised!',
                type: QuestionType.AUTHORISED,
                options: [{ value: 'Continue', nextQuestion: 'issueResolvedWithNewParts' }],
                config: {
                    completeCopy:
                        'Please click the continue button once you have fitted the new parts.',
                },
                onSubmit: () => ({}),
            },
            issueResolvedWithNewParts: {
                question: 'Issue resolved with new parts?',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Yes',
                        nextQuestion:
                            jobDetails.job_type === 'Gas Job'
                                ? 'gasSafetyChecks'
                                : 'partsAvailablePhotosOfFault',
                    },
                    { value: 'No', nextQuestion: 'issueDescriptionNotResolvedNewParts' },
                ],
                onSubmit: (value) => ({ isResolved: value === 'Yes' } as Partial<FormState>),
            },
            issueDescriptionNotResolvedNewParts: {
                question: 'Why is issue not resolved with new parts?',
                type: QuestionType.TEXT,
                config: {
                    text: { fieldLength: 10000 },
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'partsAvailablePhotosOfFault',
                    },
                ],
                defaultValue: state.issueDescription,
                onSubmit: (value) => ({ issueDescription: value } as Partial<FormState>),
            },
            partsAvailablePhotosOfFault: {
                question: 'Add photos',
                type: QuestionType.FILE_UPLOAD,
                options: [
                    {
                        value: 'Done',
                        nextQuestion: determineViewAfterPhotos(
                            jobDetails.sk_e_parts_description,
                            jobDetails.job_type,
                            state.useStructuredParts
                        ),
                    },
                ],
                onSubmit: (value) => ({ photosOfFault: value } as Partial<FormState>),
            },
            sendJobToManufacturerPhotos: {
                question: 'Add photos',
                type: QuestionType.FILE_UPLOAD,
                options: [
                    {
                        value: 'Done',
                        nextQuestion: determineViewAfterPhotos(
                            jobDetails.sk_e_parts_description,
                            jobDetails.job_type,
                            state.useStructuredParts
                        ),
                    },
                ],
                onSubmit: (value) => ({ photosOfFault: value } as Partial<FormState>),
            },
            sendJobToManufacturerCostOfParts: {
                question: 'Parts you purchased and fitted today',
                type: QuestionType.NEW_ACTUAL_COST_OF_PARTS,
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'sendJobToManufacturerActualHours',
                    },
                ],
                onSubmit: (value: ActualCostOfPartsValue) => {
                    return {
                        actualNetPartsCost: value.actualNetPartsCost,
                        actualPartsDescription: value.actualPartsDescription,
                        partsUsed: value.partsUsed,
                    } as Partial<FormState>;
                },
            },
            sendJobToManufacturerActualHours: {
                question: 'Time spent on job today',
                type: QuestionType.ACTUAL_HOURS,
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'investigationRequired',
                    },
                ],
                defaultValue: state.actualDuration,
                onSubmit: (value) => ({ actualDuration: value } as Partial<FormState>),
            },
            actualHours: {
                question: 'Time spent on job today',
                type: QuestionType.ACTUAL_HOURS,
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (_, formState) =>
                            computeNextStepAfterActualHours(
                                formState,
                                jobDetails?.package_bundle_name || ''
                            ),
                    },
                ],
                defaultValue: state.actualDuration,
                onSubmit: (value) => ({ actualDuration: value } as Partial<FormState>),
            },
            issueArea: {
                question: 'Where is the issue?',
                type: QuestionType.DROPDOWN,
                config: {
                    options: [
                        { value: 'Boiler' },
                        { value: 'Flue' },
                        {
                            value: 'Controls (eg. programmers, thermostats, zone valves, heating pumps)',
                        },
                        { value: 'Gas supply pipe' },
                        {
                            value: 'Wider central heating (eg. radiators and valves, expansion tanks, heating pipes)',
                        },
                        { value: 'Hot water cylinders and immersion heaters' },
                        { value: 'Plumbing (excluding taps and showers)' },
                        { value: 'Drains' },
                        { value: 'Mains electrical system and wiring' },
                        { value: 'Taps' },
                        { value: 'Showers' },
                        { value: 'Other' },
                    ],
                    otherConfig: {
                        question: 'Give more details',
                    },
                    text: {
                        fieldLength: 253,
                    },
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (value: string, formState) => {
                            if (formState?.isResolved) {
                                return 'issueDescriptionNotFollowOn';
                            }

                            if (!issueAreaCoveredInPolicy(value, jobDetails?.package_bundle_name)) {
                                return 'partsAvailablePhotosOfFault';
                            }

                            return value === 'Boiler' ? 'isBoilerBer' : 'issueCauses';
                        },
                    },
                ],
                defaultValue: state.issueArea,
                onSubmit: (value) => ({ issueArea: value } as Partial<FormState>),
            },
            isBoilerBer: {
                question: 'Is the boiler Beyond Economical Repair (BER)?',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Yes',
                        nextQuestion: 'berCause',
                    },
                    { value: 'No', nextQuestion: 'issueCauses' },
                ],
                onSubmit: (value) => ({ isBoilerBer: value === 'Yes' } as Partial<FormState>),
            },
            berCause: {
                question: 'Cause of BER?',
                type: QuestionType.DROPDOWN,
                config: {
                    options: [
                        { value: 'Corrosion/Rust' },
                        { value: 'Cost' },
                        { value: 'Obsolete Parts' },
                        { value: 'Scale' },
                        { value: 'Sludge' },
                    ],
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: () => {
                            return 'berDescription';
                        },
                    },
                ],
                onSubmit: (value) => ({ berCause: value } as Partial<FormState>),
            },
            berDescription: {
                question: 'Provide more information about why the boiler is BER',
                type: QuestionType.TEXT,
                config: {
                    text: { fieldLength: 10000 },
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'partsAvailablePhotosOfFault',
                    },
                ],
                onSubmit: (value) => ({ berDescription: value } as Partial<FormState>),
            },
            issueCauses: {
                question: 'Cause(s) of issue',
                type: QuestionType.CHECKBOX,
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (value: string[]) => {
                            if (value.includes('None')) {
                                return 'confidenceInDiagnosis';
                            }

                            return 'partsAvailablePhotosOfFault';
                        },
                    },
                ],
                config: {
                    options: [
                        { value: 'Installation fault' },
                        { value: 'Accidental damage' },
                        { value: 'Sludge or scale' },
                        { value: 'None', copy: 'None of the above' },
                    ],
                    singleOption: 'None',
                },
                defaultValue: state.issueCauses,
                onSubmit: (value) => ({ issueCauses: value } as Partial<FormState>),
            },
            confidenceInDiagnosis: {
                question: 'Level of confidence you can fix issue from diagnosis',
                type: QuestionType.RADIO,
                config: {
                    options: [
                        { copy: 'Low', value: '1' },
                        { copy: 'High', value: '99' },
                    ],
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (value: string) => {
                            if (+value <= 1) {
                                // confidence is low
                                return 'partsAvailablePhotosOfFault';
                            }

                            return isStructuredPartsFlow(
                                jobDetails.job_type,
                                state.useStructuredParts
                            )
                                ? 'partsNeeded'
                                : 'estimatedCostOfParts';
                        },
                        nextQuestion: isStructuredPartsFlow(
                            jobDetails.job_type,
                            state.useStructuredParts
                        )
                            ? 'partsNeeded'
                            : 'estimatedCostOfParts',
                    },
                ],
                onSubmit: (value) => ({ confidenceInDiagnosis: +value! } as Partial<FormState>),
            },
            issueDescriptionNotFollowOn: {
                question: 'Describe issue and steps you took to resolve it',
                type: QuestionType.TEXT,
                config: {
                    text: { fieldLength: 10000 },
                },
                options: [
                    {
                        value: 'Next',
                        nextQuestion:
                            jobDetails.job_type === 'Gas Job'
                                ? 'gasSafetyChecks'
                                : 'partsAvailablePhotosOfFault',
                    },
                ],
                defaultValue: state.issueDescription,
                onSubmit: (value) => ({ issueDescription: value } as Partial<FormState>),
            },
            complete: {
                question: 'Completed!',
                type: QuestionType.COMPLETE,
                options: [],
                config: {
                    completeCopy:
                        "Thanks for solving the customer's issue. Your invoice will be with you soon.",
                },
                onSubmit: () => ({}),
            },
            nextVisitScheduled: {
                question: 'Next Visit Scheduled',
                type: QuestionType.FOLLOW_ON_REQUIRED,
                options: [],
                onSubmit: () => ({}),
                config: {
                    completeCopy:
                        'The next visit has been scheduled and should be in your agenda now.',
                },
            },
            investigationRequired: {
                question: 'Hometree needs to review claim',
                type: QuestionType.INVESTIGATION_REQUIRED,
                options: [],
                onSubmit: () => ({}),
                config: {
                    completeCopy: 'Please let the customer know that we will be in touch.',
                },
            },
            rejectionNotice: {
                question: 'Possible rejection notice',
                type: QuestionType.INVESTIGATION_REQUIRED,
                options: [],
                onSubmit: () => ({}),
                config: {
                    completeCopy:
                        'Please take the customer through your findings and show evidence.<br /><br />We will be following up with an email to them shortly.',
                },
            },
            partsNeeded: {
                question: 'What is needed to resolve the issue?',
                type: QuestionType.PARTS_NEEDED,
                options: [
                    {
                        value: 'Next',
                        nextQuestionSelector: (
                            value: DetermineFollowOnIsAuthorisedInput,
                            formState: Partial<FormState>
                        ) => {
                            return determineNextFollowOnAuthorisationStep(
                                value,
                                formState,
                                accountData
                            );
                        },
                    },
                ],
                onSubmit: (value: EstimatedCostOfPartsValue) =>
                    ({
                        ...value,
                    } as Partial<FormState>),
            },
            confirmPartsNeeded: {
                question: 'Confirm authorised costs',
                type: QuestionType.NEW_CONFIRM_ESTIMATED_COST_OF_PARTS,
                options: [
                    {
                        value: 'Next',
                        nextQuestion: 'partsSource',
                    },
                ],
                onSubmit: (value: EstimatedCostOfPartsValue) =>
                    ({
                        ...value,
                    } as Partial<FormState>),
            },
            newHaveYouFittedPartsToday: {
                question: 'Have you fitted parts today?',
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Yes',
                        nextQuestion: 'newActualCostOfParts',
                    },
                    {
                        value: 'No',
                        nextQuestion: 'actualHours',
                    },
                ],
                onSubmit: () => ({}),
            },
            newActualCostOfParts: {
                question: 'Parts you purchased and fitted today',
                type: QuestionType.NEW_ACTUAL_COST_OF_PARTS,
                options: [
                    {
                        value: 'Add fitted parts',
                        nextQuestion: 'actualHours',
                    },
                ],
                onSubmit: (value: ActualCostOfPartsValue) => {
                    return {
                        actualNetPartsCost: value.actualNetPartsCost,
                        actualPartsDescription: value.actualPartsDescription,
                        partsUsed: value.partsUsed,
                    } as Partial<FormState>;
                },
                // If they have fixed in same visit, then use partsRequested
                defaultValue:
                    state.partsUsed ||
                    (state.partsSource === 'canFitPartsNow' && state.partsRequested) ||
                    jobDetails.parts,
            },
            canLabourCostBeLowered: {
                question: ``,
                body: `The cost requested to resolve issue is higher than what other engineers in the Network would request for the same repair. 
                <br/><br/>Please review and submit again in order to receive automatic authorisation.`,
                type: QuestionType.SELECT,
                options: [
                    {
                        value: 'Can lower my cost',
                        nextQuestion: 'partsNeeded',
                    },
                    {
                        value: 'Cannot lower my cost',
                        nextQuestionSelector: (_, formState) =>
                            determineNextFollowOnAuthorisationStep(
                                {
                                    numberOfParts: (formState.partsRequested || []).length,
                                    labourCost: labourHoursToCost(
                                        formState.estimatedHoursNeeded || 0
                                    ),
                                    totalCostOfFix: totalCostOfJob(
                                        formState.estimatedHoursNeeded || 0,
                                        formState.estimatedPartsCost || 0
                                    ),
                                    boilerMake: formState.boilerMake || '',
                                    maxLabourCost: labourHoursToCost(
                                        maxLabourAuthorised({
                                            partsRequested: formState.partsRequested || [],
                                            warningLabourCostShown: true,
                                        }) || 0
                                    ),
                                    previousEngineerVisits: Number(
                                        formState.previousEngineerVisits || 0
                                    ),
                                    partsRequested: formState.partsRequested || [],
                                    issueArea: formState.issueArea || '',
                                    manuallyAuthorised: jobDetails.sk_authorise_follow_on,
                                },
                                { warningLabourCostShown: true },
                                accountData
                            ),
                    },
                ],
                config: {
                    type: 'warning',
                    otherConfig: {
                        className: 'CanLowerMyLabourCosts',
                    },
                },
                onSubmit: () => {
                    return {
                        warningLabourCostShown: true,
                    } as Partial<FormState>;
                },
            },
            ...gasSafetyQuestions(jobDetails, state),
        };
    };
